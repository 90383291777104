/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React from "react";
import tw, { css } from "twin.macro";
import { graphql } from "gatsby";
import { Footer, Layout, PageBuilder } from "~components";
import * as PageBuilderSections from "~components/PageBuilder/sections";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component
 * Buildable page template for Sanity data sources.
 */
const Page = ({ data, location }) => {
  const content = data?.sanityPage || {};

  let pageBuilderContent = {};

  if (content?.pagebuilder) {
    pageBuilderContent = content.pagebuilder;
  }

  const { seoDescription, seoKeywords } = content;

  //

  return (
    <>
      <Layout location={location} seo={{ seoDescription, seoKeywords }}>
        <div
          css={[
            css`
              padding-top: 56px;

              ${MEDIA_QUERIES?.desktop} {
                padding-top: 90px;
              }
            `,
            tw`relative`
          ]}
        >
          <PageBuilder
            content={pageBuilderContent}
            sections={PageBuilderSections}
          />
        </div>

        <Footer />
      </Layout>
    </>
  );
};

export default Page;

export const query = graphql`
  query SanityPage($id: String!) {
    sanityPage(id: { eq: $id }) {
      title
      seoDescription
      seoKeywords

      ...PageBuilderFragment
    }
  }
`;
